@use "../configure/variable" as *;
@use "../configure/function" as *;

.el-copyright {
  font-size: rem(12px);
  line-height: 1;
  letter-spacing: 0.03em;
}

.el-copyright_wrapper {
  text-align: center;
}
