// imgPath (relative path of img directory from style.css)
$imgPath: "../img/";

// breakpoint
$bp: 834px;

// color
$cWhite: #ffffff;
$cBlack: #000000;
$cGold: #978f10;
$cGray: #707070;
$cSlightlyLightGray: #e7e7e7;
$cLightGray: #f6f6f6;

// fontSize
$fzBaseSP: 14px;
$fzBasePC: 16px;

// fontFamily
$ffBase: "Times New Roman", "游明朝", "Yu Mincho", "游明朝体", "YuMincho",
  "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E",
  "ＭＳ Ｐ明朝", "MS PMincho", serif;

// width
$wBase: 1220px;

// padding-left,-right
$plrPC: 20px;
$plrSP: 4%;

// z-index
$zIndex: (
  header: 3,
  burgerBtn: 5,
  burgerMenu: 4,
  modal: 10,
);

// animation duration
$duration: (
  fade: 0.4s,
);
