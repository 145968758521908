@use "../configure/variable" as *;
@use "../configure/function" as *;

ul.bl-toggleTab {
  display: flex;
  justify-content: center;

  li {
    display: inline-block;
    .is-selected {
      text-decoration: underline;
    }
  }
  li:not(:last-child) {
    margin-right: 4em;
  }

  .ly-news & {
    margin-bottom: 40px;
  }
}
