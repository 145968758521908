@use "../configure/variable" as *;
@use "../configure/function" as *;

.ly-header {
  position: sticky;
  top: 0;
  z-index: map-get($zIndex, header);
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: $cWhite;
  box-shadow: 0 -5px 10px;
  transition: 0.3s;

  .ly-header_inner {
    padding-right: $plrPC;
    padding-left: $plrPC;
    @include mq($bp) {
      padding-right: $plrSP;
      padding-left: $plrSP;
    }
  }

  &.ly-header__fixed {
    position: fixed;
  }

  &.is-transparent {
    background-color: transparent;
    box-shadow: none;
    color: $cWhite;
  }
}
