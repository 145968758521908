@use "../configure/variable" as *;
@use "../configure/function" as *;

.ly-footer {
  padding-top: 64px;
  padding-bottom: 60px;
  background-color: $cGold;
  background-image: url(img("bg03.jpg"));
  .ly-footer_inner {
    padding-right: $plrPC;
    padding-left: $plrPC;
    @include mq($bp) {
      padding-right: $plrSP;
      padding-left: $plrSP;
    }
  }
}
