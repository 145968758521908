@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-form {
}
.bl-form_heading {
  margin-bottom: clamp(rem(14px), 4vh, 1.775em);
  text-align: center;
  font-size: rem(20px);
  font-weight: normal;
  &::after {
    content: "";
    display: block;
    margin-top: clamp(5px, 3vh, 1.5em);
    height: 1px;
    background-color: $cSlightlyLightGray;
  }
}
label {
  display: block;
  margin-bottom: clamp(rem(7px), 3vh, rem(14px));
}
.bl-form_input {
  display: block;
  width: 100%;
  margin-bottom: clamp(rem(7px), 2vh, rem(14px));
  padding: rem(4px) rem(8px);
  background-color: $cWhite;
  border: 1px solid $cSlightlyLightGray;
  font-size: max(rem(16px), 16px);
}
input::placeholder,
select:invalid {
  font-size: rem(14px);
  color: #787878;
}
.bl-form_submitBtn {
  display: inline-block;
  padding: 0.8125em 4.1875em;
  border: 1px solid $cGray;
  color: $cBlack;
  @include hover {
    background-color: $cGray;
    color: $cWhite;
  }
}
.bl-form_submitBtn_wrapper {
  text-align: center;
  margin-top: clamp(15px, 4vh, rem(64px));
}
