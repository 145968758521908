*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:where(body) {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
:where(img, picture, video, canvas, svg) {
  display: block;
  max-width: 100%;
}
:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}
:where(button, label, select, summary, [role="button"], [role="option"]) {
  cursor: pointer;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}
