@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-kv {
  display: grid;
}
.bl-kv_imgBox {
  grid-row: 1/-1;
  grid-column: 1/-1;
  width: 100%;
  aspect-ratio: 1920/500;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.bl-kv_pageTtl {
  font-size: clamp(rem(20px), 5vw, rem(34px));
  color: $cWhite;
  line-height: 1;
  &_wrapper {
    grid-row: 1/-1;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
