@use "../configure/variable" as *;
@use "../configure/function" as *;

ul.bl-mediaUnit {
  li:not(:last-child) {
    margin-bottom: 122px;
    @include mq($bp) {
      margin-bottom: 0;
    }
  }
}
