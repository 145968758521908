@use "../configure/variable" as *;
@use "./variable" as *;
@use "sass:math";

// mediaQuery
@mixin mq($bp) {
  @media screen and (max-width:#{$bp}) {
    @content;
  }
}

//hover
@mixin hover {
  @media (hover: none) {
    &:active {
      @content;
    }
  }
  @media (hover: hover) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

//line-clamp
@mixin line-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// function px to rem
@function rem($px) {
  @return calc($px / $fzBasePC) * 1rem;
}
@function rem_sp($px) {
  @return calc($px / $fzBaseSP) * 1rem;
}

// halfLeading
@function halfLeading($fontSize, $lineHeight) {
  @return math.div(($fontSize * $lineHeight)-$fontSize, 2);
}

// imgPath
@function img($filename) {
  @return "#{$imgPath}#{$filename}";
}
