@use "../configure/variable" as *;
@use "../configure/function" as *;

.el-notice {
  &::before {
    content: "\002a";
    display: inline-block;
    vertical-align: -0.15em;
    padding-right: 0.3em;
  }
}
