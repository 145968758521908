@use "../configure/variable" as *;
@use "../configure/function" as *;

.el-closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;

  & > span {
    position: relative;
    display: block;
    width: 100%;
  }
  & > span::before,
  & > span::after {
    position: absolute;
    content: "";
    display: block;
    background: $cGray;
    width: 100%;
    height: 2px;
    border-radius: 100vh;
  }
  span::before {
    top: -1px;
    transform: rotate(-45deg);
  }
  span::after {
    bottom: -1px;
    transform: rotate(45deg);
  }

  .bl-modal & {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
  }
}
