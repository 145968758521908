@use "../configure/variable" as *;
@use "../configure/function" as *;

.ly-news {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $cWhite;
  .ly-news_inner {
    max-width: $wBase;
    margin-right: auto;
    margin-left: auto;
    padding-right: $plrPC;
    padding-left: $plrPC;
    @include mq($bp) {
      padding-right: $plrSP;
      padding-left: $plrSP;
    }
  }
}
