@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-burgerMenu {
  display: none;
}

@include mq($bp) {
  .bl-burgerMenu {
    // test
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 60px $plrSP;
    background-color: rgba($cWhite, 0.93);
    overflow: auto;
    z-index: map-get($zIndex, burgerMenu);
  }
  .bl-burgerMenu_nav {
    margin-bottom: 60px;

    ul {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      li {
        writing-mode: vertical-rl;
        text-orientation: upright;
        font-size: rem(18px);
        letter-spacing: 0.8em;
        color: $cBlack;
      }
      li:not(:last-of-type) {
        margin-left: 2em;
      }
      a {
        @include hover {
          opacity: 0.6;
        }
      }
    }
  }
  .bl-burgerMenu_logo {
    width: 145px;
    aspect-ratio: 145/92;
    margin-right: auto;
    margin-bottom: 72px;
    margin-left: auto;
  }
  .bl-burgerMenu_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    font-style: normal;
    color: $cBlack;
    p {
      margin: 0 0.5em 1.5em;
      flex: 0 0 auto;
    }
  }
  .bl-burgerMenu_info_txt {
    letter-spacing: 0.18em;
  }
}
