@use "../configure/variable" as *;
@use "../configure/function" as *;
@use "sass:math";

:root {
  font-size: percentage(math.div($fzBasePC, 16px));
  @include mq($bp) {
    font-size: percentage(math.div($fzBaseSP, 16px));
  }
}
body {
  font-family: $ffBase;
  line-height: 2;
  background-color: $cWhite;
  color: $cBlack;
  @include mq($bp) {
    line-height: 1.7;
  }
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
select {
  appearance: none;
}

button {
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
}
input,
select {
  appearance: none;
}
