@use "../configure/variable" as *;
@use "../configure/function" as *;

.el-btn {
  display: inline-block;
  padding: 0.7em 3em;
  border: 1px solid $cGray;

  .ly-service & {
    @include mq($bp) {
      border-color: $cWhite;
      background-color: rgba($cWhite, 0.2);
    }
  }
}

a.el-btn {
  @include hover {
    background-color: $cGray;
    color: $cWhite;
  }

  .ly-service & {
    @include mq($bp) {
      @include hover {
        background-color: $cWhite;
        color: $cBlack;
      }
    }
  }
}
