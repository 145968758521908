@use "../configure/variable" as *;
@use "../configure/function" as *;

.el-googleMap {
  max-width: 792px;
  width: 100%;
  aspect-ratio: 792/592;
  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }

  .ly-access & {
    margin-right: auto;
    margin-left: auto;
  }
}
