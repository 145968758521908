@use "../configure/variable" as *;
@use "../configure/function" as *;

.js-fadeOut {
  animation-name: fadeOut;
  animation-duration: map-get($duration, fade);
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
