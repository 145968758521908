@use "../configure/variable" as *;
@use "../configure/function" as *;

.el-beforeIconHeading {
  font-size: rem(36px);
  font-weight: normal;
  text-align: center;
  &::before {
    content: "";
    display: block;
    width: 40px;
    aspect-ratio: 1/1;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 6px;
    background-image: url(img("logo02.png"));
    background-image: image-set(
      url(img("logo02.png")) 1x,
      url(img("logo02@2x.png")) 2x
    );
  }

  .ly-plan & {
    margin-bottom: 1.3em;
  }
  .ly-news & {
    margin-bottom: 0.6em;
  }
  .ly-access & {
    margin-bottom: 1.75em;
  }
}
