@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-aboutCont {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include mq($bp) {
    flex-direction: column;
  }

  .bl-aboutCont_ttl {
    display: inline-block;
    writing-mode: vertical-rl;
    text-orientation: upright;
    margin-right: calc(-1 * halfLeading(rem(28px), 2.2));
    margin-left: calc(-1 * halfLeading(rem(28px), 2.2));
    height: rem(28px * 11 * 1.5);
    font-size: rem(28px);
    line-height: 2.1;
    letter-spacing: 0.5em;
    @include mq($bp) {
      height: auto;
      writing-mode: horizontal-tb;
      text-orientation: sideways;
      margin-right: 0;
      margin-bottom: 2em;
      margin-left: 0;
      line-height: 1.7;
      letter-spacing: 0.2em;
    }
    &_2ndLine {
      margin-top: rem(28px * 2 * 1.5);
      @include mq($bp) {
        margin-top: 0;
      }
    }
  }

  .bl-aboutCont_txt {
    writing-mode: vertical-rl;
    text-orientation: upright;
    margin-top: rem(28px * 2 * 1.5);
    margin-right: calc(-1 * halfLeading(rem(16px), 2.2));
    margin-left: calc(-1 * halfLeading(rem(16px), 2.2));
    height: rem(16px * 20 * 1.37);
    line-height: 2.2;
    letter-spacing: 0.37em;

    @include mq($bp) {
      writing-mode: horizontal-tb;
      text-orientation: sideways;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 2.2em;
      margin-left: 0;
      height: auto;
      letter-spacing: 0;
    }
  }

  .bl-aboutCont_signature {
    display: inline-block;
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-align: end;
    margin-bottom: 1em;
    letter-spacing: 0.3em;
    line-height: 1;
    font-size: rem(22px);

    @include mq($bp) {
      writing-mode: horizontal-tb;
      text-orientation: sideways;
      margin-top: 3em;
      margin-bottom: 0;
    }
  }

  .ly-about & {
    margin-right: auto;
    margin-left: auto;
  }
}
