@use "../configure/variable" as *;
@use "../configure/function" as *;

.el-beforeIconBtn {
  position: relative;
  display: inline-block;
  padding-top: clamp(0.7em, 1vw, 1.1875em);
  padding-right: min(3vw, 2.3125em);
  padding-bottom: clamp(0.7em, 1vw, 1.1875em);
  padding-right: min(3vw, 2.3125em);
  padding-left: calc(min(3vw, 2.3125em) + 2em);
  background-color: $cGold;
  color: $cWhite;
  line-height: 1;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: min(3vw, 2.2em);
    transform: translateY(-50%);
    width: 1.375em;
    aspect-ratio: 1/1;
    background-image: url(img("calender.png"));
    background-size: cover;
  }
}

button.el-beforeIconBtn {
  @include hover {
    opacity: 0.7;
  }
}

.ly-header {
  .el-beforeIconBtn_wrapper {
    flex: 1 0 auto;
    text-align: right;
  }
}
