@charset "UTF-8";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:where(body) {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

:where(img, picture, video, canvas, svg) {
  display: block;
  max-width: 100%;
}

:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}

:where(button, label, select, summary, [role=button], [role=option]) {
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}

:root {
  font-size: 100%;
}
@media screen and (max-width: 834px) {
  :root {
    font-size: 87.5%;
  }
}

body {
  font-family: "Times New Roman", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 2;
  background-color: #ffffff;
  color: #000000;
}
@media screen and (max-width: 834px) {
  body {
    line-height: 1.7;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

select {
  appearance: none;
}

button {
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
}

input,
select {
  appearance: none;
}

.hp-brPC {
  display: block;
}
@media screen and (max-width: 834px) {
  .hp-brPC {
    display: none;
  }
}

.ly-about {
  padding-top: 64px;
  padding-bottom: 95px;
  background-color: #f6f6f6;
  background-image: url("../img/bg.jpg");
}
.ly-about .ly-about_inner {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 80px;
  padding-left: 80px;
}
@media screen and (max-width: 834px) {
  .ly-about .ly-about_inner {
    padding-right: 4%;
    padding-left: 4%;
    max-width: 600px;
  }
}

.ly-access {
  padding-top: 60px;
  padding-bottom: 110px;
  background-color: #f6f6f6;
  background-image: url("../img/bg.jpg");
}
.ly-access .ly-access_inner {
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .ly-access .ly-access_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.ly-cont {
  padding-top: 36px;
  padding-bottom: 150px;
}

.ly-cont_inner {
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .ly-cont_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.ly-cont.ly-cont__onsen {
  padding-bottom: 96px;
}

.ly-copyright {
  padding-top: 12px;
  padding-bottom: 15px;
  background-color: #000000;
  color: #ffffff;
}
.ly-copyright .ly-copyright_inner {
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .ly-copyright .ly-copyright_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.ly-footer {
  padding-top: 64px;
  padding-bottom: 60px;
  background-color: #978f10;
  background-image: url("../img/bg03.jpg");
}
.ly-footer .ly-footer_inner {
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .ly-footer .ly-footer_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.ly-header {
  position: sticky;
  top: 0;
  z-index: 3;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #ffffff;
  box-shadow: 0 -5px 10px;
  transition: 0.3s;
}
.ly-header .ly-header_inner {
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .ly-header .ly-header_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}
.ly-header.ly-header__fixed {
  position: fixed;
}
.ly-header.is-transparent {
  background-color: transparent;
  box-shadow: none;
  color: #ffffff;
}

.ly-news {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #ffffff;
}
.ly-news .ly-news_inner {
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .ly-news .ly-news_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.ly-plan {
  padding-top: 60px;
  padding-bottom: 130px;
  background-color: #978f10;
  background-image: url("../img/bg02.jpg");
}
@media screen and (max-width: 834px) {
  .ly-plan {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.ly-plan .ly-plan_inner {
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .ly-plan .ly-plan_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.ly-service {
  padding-top: 106px;
  padding-bottom: 78px;
  background-color: #ffffff;
}
@media screen and (max-width: 834px) {
  .ly-service {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.bl-aboutCont {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media screen and (max-width: 834px) {
  .bl-aboutCont {
    flex-direction: column;
  }
}
.bl-aboutCont .bl-aboutCont_ttl {
  display: inline-block;
  writing-mode: vertical-rl;
  text-orientation: upright;
  margin-right: -1.05rem;
  margin-left: -1.05rem;
  height: 28.875rem;
  font-size: 1.75rem;
  line-height: 2.1;
  letter-spacing: 0.5em;
}
@media screen and (max-width: 834px) {
  .bl-aboutCont .bl-aboutCont_ttl {
    height: auto;
    writing-mode: horizontal-tb;
    text-orientation: sideways;
    margin-right: 0;
    margin-bottom: 2em;
    margin-left: 0;
    line-height: 1.7;
    letter-spacing: 0.2em;
  }
}
.bl-aboutCont .bl-aboutCont_ttl_2ndLine {
  margin-top: 5.25rem;
}
@media screen and (max-width: 834px) {
  .bl-aboutCont .bl-aboutCont_ttl_2ndLine {
    margin-top: 0;
  }
}
.bl-aboutCont .bl-aboutCont_txt {
  writing-mode: vertical-rl;
  text-orientation: upright;
  margin-top: 5.25rem;
  margin-right: -0.6rem;
  margin-left: -0.6rem;
  height: 27.4rem;
  line-height: 2.2;
  letter-spacing: 0.37em;
}
@media screen and (max-width: 834px) {
  .bl-aboutCont .bl-aboutCont_txt {
    writing-mode: horizontal-tb;
    text-orientation: sideways;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 2.2em;
    margin-left: 0;
    height: auto;
    letter-spacing: 0;
  }
}
.bl-aboutCont .bl-aboutCont_signature {
  display: inline-block;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-align: end;
  margin-bottom: 1em;
  letter-spacing: 0.3em;
  line-height: 1;
  font-size: 1.375rem;
}
@media screen and (max-width: 834px) {
  .bl-aboutCont .bl-aboutCont_signature {
    writing-mode: horizontal-tb;
    text-orientation: sideways;
    margin-top: 3em;
    margin-bottom: 0;
  }
}
.ly-about .bl-aboutCont {
  margin-right: auto;
  margin-left: auto;
}

.bl-accessCont .bl-accessCont_media {
  display: flex;
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .bl-accessCont .bl-accessCont_media {
    flex-direction: column;
  }
}
.bl-accessCont .bl-accessCont_media_imgBox {
  flex: 55.6% 1 0;
  aspect-ratio: 656/370;
  margin-right: 7%;
}
@media screen and (max-width: 834px) {
  .bl-accessCont .bl-accessCont_media_imgBox {
    flex: auto 1 0;
    margin-right: 0;
    margin-bottom: 2em;
  }
}
.bl-accessCont .bl-accessCont_media_imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bl-accessCont .bl-accessCont_media_body {
  padding-top: 0.75em;
}
.bl-accessCont .bl-accessCont_media_body dl,
.bl-accessCont .bl-accessCont_media_body dt,
.bl-accessCont .bl-accessCont_media_body dd {
  margin-bottom: 0.5em;
}
@media screen and (max-width: 834px) {
  .bl-accessCont .bl-accessCont_media_body dl,
  .bl-accessCont .bl-accessCont_media_body dt,
  .bl-accessCont .bl-accessCont_media_body dd {
    margin-bottom: 1em;
  }
}
.bl-accessCont .bl-accessCont_media_body dt {
  font-weight: 800;
}

.bl-breadcrumb {
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.ly-cont .bl-breadcrumb {
  margin-bottom: 6.125em;
}

.bl-breadcrumb_txt {
  line-height: 1;
}
.bl-breadcrumb_txt:not(:last-of-type)::after {
  content: "";
  transform: rotate(45deg) translateY(-0.2em);
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin-left: 0.3em;
  margin-right: 0.6em;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
}
@media (hover: none) {
  .bl-breadcrumb_txt a:active {
    opacity: 0.6;
  }
}
@media (hover: hover) {
  .bl-breadcrumb_txt a:hover, .bl-breadcrumb_txt a:focus {
    opacity: 0.6;
  }
}

.bl-burgerBtn {
  display: none;
}
@media screen and (max-width: 834px) {
  .bl-burgerBtn {
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
  }
  .bl-burgerBtn > span, .bl-burgerBtn > span::before, .bl-burgerBtn > span::after {
    display: block;
    background: #000000;
    width: 100%;
    height: 2px;
    border-radius: 100vh;
    transition: 0.3s;
  }
  .ly-header.is-transparent .bl-burgerBtn > span, .ly-header.is-transparent .bl-burgerBtn > span::before, .ly-header.is-transparent .bl-burgerBtn > span::after {
    background-color: #ffffff;
  }
  .bl-burgerBtn > span {
    position: relative;
  }
  .bl-burgerBtn > span::before {
    position: absolute;
    content: "";
    top: -9px;
  }
  .bl-burgerBtn > span::after {
    position: absolute;
    content: "";
    bottom: -9px;
  }
  .bl-burgerBtn.is-clicked span {
    background: rgba(0, 0, 0, 0) !important;
  }
  .bl-burgerBtn.is-clicked span::before {
    top: 0;
    transform: rotate(-45deg);
    background-color: #000000 !important;
  }
  .bl-burgerBtn.is-clicked span::after {
    bottom: 0;
    transform: rotate(45deg);
    background-color: #000000 !important;
  }
  .ly-header .bl-burgerBtn {
    flex: 0 0 20px;
    margin-left: 10px;
  }
}

.bl-burgerMenu {
  display: none;
}

@media screen and (max-width: 834px) {
  .bl-burgerMenu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 60px 4%;
    background-color: rgba(255, 255, 255, 0.93);
    overflow: auto;
    z-index: 4;
  }
  .bl-burgerMenu_nav {
    margin-bottom: 60px;
  }
  .bl-burgerMenu_nav ul {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }
  .bl-burgerMenu_nav ul li {
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 1.125rem;
    letter-spacing: 0.8em;
    color: #000000;
  }
  .bl-burgerMenu_nav ul li:not(:last-of-type) {
    margin-left: 2em;
  }
}
@media screen and (max-width: 834px) and (hover: none) {
  .bl-burgerMenu_nav ul a:active {
    opacity: 0.6;
  }
}
@media screen and (max-width: 834px) and (hover: hover) {
  .bl-burgerMenu_nav ul a:hover, .bl-burgerMenu_nav ul a:focus {
    opacity: 0.6;
  }
}
@media screen and (max-width: 834px) {
  .bl-burgerMenu_logo {
    width: 145px;
    aspect-ratio: 145/92;
    margin-right: auto;
    margin-bottom: 72px;
    margin-left: auto;
  }
}
@media screen and (max-width: 834px) {
  .bl-burgerMenu_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    font-style: normal;
    color: #000000;
  }
  .bl-burgerMenu_info p {
    margin: 0 0.5em 1.5em;
    flex: 0 0 auto;
  }
}
@media screen and (max-width: 834px) {
  .bl-burgerMenu_info_txt {
    letter-spacing: 0.18em;
  }
}
.bl-copy {
  text-align: center;
}
.ly-cont .bl-copy {
  margin-top: -0.5rem;
  margin-bottom: calc(78px - 0.5rem);
}

.bl-footerCont_nav {
  margin-bottom: 78px;
}
.bl-footerCont_nav ul {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
.bl-footerCont_nav ul li {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 1.125rem;
  letter-spacing: 0.8em;
}
.bl-footerCont_nav ul li:not(:last-of-type) {
  margin-left: 1em;
}
@media (hover: none) {
  .bl-footerCont_nav ul a:active {
    opacity: 0.6;
  }
}
@media (hover: hover) {
  .bl-footerCont_nav ul a:hover, .bl-footerCont_nav ul a:focus {
    opacity: 0.6;
  }
}

.bl-footerCont_logo {
  width: 145px;
  aspect-ratio: 145/92;
  margin-right: auto;
  margin-bottom: 76px;
  margin-left: auto;
}

.bl-footerCont_info {
  font-style: normal;
  line-height: 1;
}
.bl-footerCont_info_wrapper {
  display: flex;
  justify-content: center;
}
.bl-footerCont_info_wrapper:not(:last-of-type) {
  margin-bottom: 1.4375em;
}
.bl-footerCont_info_01 {
  margin-right: 1em;
}
.bl-footerCont_info_02 {
  letter-spacing: 0.14em;
}
.bl-footerCont_info_03 {
  margin-right: 2em;
}

.bl-form_heading {
  margin-bottom: clamp(0.875rem, 4vh, 1.775em);
  text-align: center;
  font-size: 1.25rem;
  font-weight: normal;
}
.bl-form_heading::after {
  content: "";
  display: block;
  margin-top: clamp(5px, 3vh, 1.5em);
  height: 1px;
  background-color: #e7e7e7;
}

label {
  display: block;
  margin-bottom: clamp(0.4375rem, 3vh, 0.875rem);
}

.bl-form_input {
  display: block;
  width: 100%;
  margin-bottom: clamp(0.4375rem, 2vh, 0.875rem);
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  font-size: max(1rem, 16px);
}

input::placeholder,
select:invalid {
  font-size: 0.875rem;
  color: #787878;
}

.bl-form_submitBtn {
  display: inline-block;
  padding: 0.8125em 4.1875em;
  border: 1px solid #707070;
  color: #000000;
}
@media (hover: none) {
  .bl-form_submitBtn:active {
    background-color: #707070;
    color: #ffffff;
  }
}
@media (hover: hover) {
  .bl-form_submitBtn:hover, .bl-form_submitBtn:focus {
    background-color: #707070;
    color: #ffffff;
  }
}

.bl-form_submitBtn_wrapper {
  text-align: center;
  margin-top: clamp(15px, 4vh, 4rem);
}

@media screen and (max-width: 834px) {
  .bl-globalNav {
    display: none;
  }
}
.bl-globalNav ul {
  display: flex;
  justify-content: flex-start;
}
.bl-globalNav li {
  display: inline-block;
}
.bl-globalNav li:not(:last-of-type) {
  margin-right: 30px;
}
@media (hover: none) {
  .bl-globalNav a:active {
    opacity: 0.6;
  }
}
@media (hover: hover) {
  .bl-globalNav a:hover, .bl-globalNav a:focus {
    opacity: 0.6;
  }
}
.bl-headerCont .bl-globalNav {
  flex: 1 1 auto;
}

.bl-headerCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bl-headerCont .bl-headerCont_logo {
  margin-right: 6.38%;
}
.bl-headerCont .bl-headerCont_logo_white {
  display: none;
}
.bl-headerCont .bl-headerCont_logo_black {
  display: block;
}
.ly-header.is-transparent .bl-headerCont .bl-headerCont_logo_white {
  display: block;
}
.ly-header.is-transparent .bl-headerCont .bl-headerCont_logo_black {
  display: none;
}

.bl-horizCard {
  display: flex;
  background-color: #f6f6f6;
  padding: 15px 10px;
}
.bl-horizCard .bl-horizCard_imgBox {
  flex: 100px 0 0;
  aspect-ratio: 1/1;
  margin-right: 30px;
}
.bl-horizCard .bl-horizCard_imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .bl-horizCard .bl-horizCard_imgBox {
    flex: max(16%, 70px) 0 0;
    margin-right: 10px;
  }
}
.bl-horizCard .bl-horizCard_body time {
  display: block;
  margin-bottom: 0.3em;
  font-size: 0.875rem;
  line-height: 1;
}
.bl-horizCard .bl-horizCard_body .bl-horizCard_txt {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (hover: none) {
  a.bl-horizCard:active {
    opacity: 0.8;
  }
}
@media (hover: hover) {
  a.bl-horizCard:hover, a.bl-horizCard:focus {
    opacity: 0.8;
  }
}

ul.bl-horizCardUnit {
  display: grid;
  column-gap: 46px;
  row-gap: 20px;
  align-items: stretch;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 834px) {
  ul.bl-horizCardUnit {
    grid-template-columns: repeat(1, 1fr);
  }
}

.bl-kv {
  display: grid;
}

.bl-kv_imgBox {
  grid-row: 1/-1;
  grid-column: 1/-1;
  width: 100%;
  aspect-ratio: 1920/500;
}
.bl-kv_imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bl-kv_pageTtl {
  font-size: clamp(1.25rem, 5vw, 2.125rem);
  color: #ffffff;
  line-height: 1;
}
.bl-kv_pageTtl_wrapper {
  grid-row: 1/-1;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bl-media {
  display: flex;
}
@media screen and (max-width: 834px) {
  .bl-media {
    display: grid;
  }
}
.bl-media .bl-media_imgBox {
  flex: 0 1 54.6%;
  aspect-ratio: 1050/545;
  margin-right: 6.5%;
}
.bl-media .bl-media_imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .bl-media .bl-media_imgBox {
    margin-right: 0;
    aspect-ratio: initial;
    grid-area: 1/-1;
  }
}
.bl-media .bl-media_body {
  flex: 0 1 auto;
  padding: 2em 1em;
}
@media screen and (max-width: 834px) {
  .bl-media .bl-media_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    grid-area: 1/-1;
    padding: 3em 4%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
  }
}
.bl-media .bl-media_ttl {
  margin-top: -0.81rem;
  margin-bottom: calc(min(4vw, 70px) - 0.81rem);
  font-weight: normal;
  font-size: 2.25rem;
  line-height: 1.72;
}
@media screen and (max-width: 834px) {
  .bl-media .bl-media_ttl {
    font-size: 1.7142857143rem;
    margin-bottom: calc(30px - 0.54rem);
  }
}
.bl-media .bl-media_txt {
  margin-bottom: min(4vw, 65px);
}
@media screen and (max-width: 834px) {
  .bl-media .bl-media_txt {
    margin-bottom: 30px;
  }
}
.bl-media.bl-media__rev {
  flex-direction: row-reverse;
}
.bl-media.bl-media__rev .bl-media_imgBox {
  margin-right: 0;
  margin-left: 7.4%;
}
@media screen and (max-width: 834px) {
  .bl-media.bl-media__rev .bl-media_imgBox {
    margin-left: 0;
  }
}
@media screen and (max-width: 834px) {
  .bl-media.bl-media__rev .bl-media_body {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

ul.bl-mediaUnit li:not(:last-child) {
  margin-bottom: 122px;
}
@media screen and (max-width: 834px) {
  ul.bl-mediaUnit li:not(:last-child) {
    margin-bottom: 0;
  }
}

.bl-modal {
  position: relative;
  width: min(550px, 100%);
  max-height: 100%;
  border: 1px solid #707070;
  background-color: #ffffff;
  padding-top: clamp(10px, 4vh, 30px);
  padding-bottom: clamp(30px, 10vh, 90px);
  overflow: auto;
}

.bl-modal_bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 4%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 10;
}

.bl-modal_inner {
  max-width: 430px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media screen and (max-width: 834px) {
  .bl-modal_inner {
    padding-right: 4%;
    padding-left: 4%;
  }
}

.bl-noticeList {
  line-height: 1.5;
}
.bl-overlapMedia .bl-noticeList {
  font-size: 0.875rem;
}

.bl-overlapMedia {
  display: flex;
  padding-bottom: calc(-1 * (-5% - 80px));
}
@media screen and (max-width: 834px) {
  .bl-overlapMedia {
    flex-direction: column;
    padding-bottom: 0;
  }
}
.bl-overlapMedia.bl-overlapMedia__rev {
  flex-direction: row-reverse;
}
@media screen and (max-width: 834px) {
  .bl-overlapMedia.bl-overlapMedia__rev {
    flex-direction: column;
  }
}

.bl-overlapMedia_imgBox {
  flex: 0 1 44.5%;
  aspect-ratio: 525/300;
  z-index: 1;
}
.bl-overlapMedia_imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .bl-overlapMedia_imgBox {
    flex: 0 1 auto;
    width: 92%;
  }
}

.bl-overlapMedia_body_wrapper {
  flex: 1 0 55%;
  background-image: url("../img/bg.jpg");
  margin-top: 5%;
  margin-bottom: calc(-5% - 80px);
  margin-left: -13.3%;
  padding-top: clamp(20px, 6%, 60px);
  padding-right: clamp(20px, 6%, 60px);
  padding-bottom: clamp(20px, 6%, 60px);
  padding-left: clamp(15% + 30px, 20%, 15% + 80px);
}
@media screen and (max-width: 834px) {
  .bl-overlapMedia_body_wrapper {
    flex: 0 1 auto;
    width: 92%;
    align-self: end;
    margin-top: -30%;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: clamp(30% + 20px, 35%, 30% + 60px);
    padding-bottom: clamp(20px, 5%, 60px);
    padding-left: clamp(20px, 3%, 60px);
  }
}

.bl-overlapMedia_ttl {
  font-size: 1.5rem;
  font-weight: normal;
  margin-top: -0.75rem;
  margin-bottom: calc(clamp(1.5rem, 5vw, 3.25rem) - 0.75rem);
}

.bl-overlapMedia_txt {
  margin-top: -0.5rem;
  margin-bottom: calc(clamp(1.5rem, 5vw, 3.25rem) - 0.5rem);
}

@media screen and (max-width: 834px) {
  .bl-overlapMedia__rev .bl-overlapMedia_imgBox {
    align-self: end;
  }
}
.bl-overlapMedia__rev .bl-overlapMedia_body_wrapper {
  margin-right: -13.3%;
  margin-left: 0;
  padding-right: clamp(15% + 30px, 20%, 15% + 80px);
  padding-left: clamp(20px, 6%, 60px);
}
@media screen and (max-width: 834px) {
  .bl-overlapMedia__rev .bl-overlapMedia_body_wrapper {
    align-self: start;
    margin-right: 0;
    padding-right: clamp(20px, 3%, 60px);
  }
}

.bl-overlapMediaUnit .bl-overlapMedia:not(:last-child) {
  margin-bottom: 5.25em;
}
.bl-overlapMediaUnit .bl-overlapMedia:last-child {
  margin: 0;
}

.ly-cont__onsen .bl-overlapMediaUnit {
  margin-bottom: 157px;
}

.bl-table {
  padding-top: min(10vw, 96px);
  padding-bottom: min(8vw, 74px);
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.bl-table_ttl {
  margin-top: -0.8125rem;
  margin-bottom: calc(51px - 0.8125rem);
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
}

table.bl-table_cont {
  max-width: 880px;
  margin-right: auto;
  margin-left: auto;
  border-collapse: collapse;
}
table.bl-table_cont th,
table.bl-table_cont td {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  border-top: 1px solid #e7e7e7;
  font-size: 0.9375rem;
  line-height: 1.47;
  text-align: left;
  vertical-align: top;
}
table.bl-table_cont tr:last-of-type th,
table.bl-table_cont tr:last-of-type td {
  border-bottom: 1px solid #e7e7e7;
}
table.bl-table_cont th {
  white-space: nowrap;
  padding-right: min(10vw, 10.7em);
  font-weight: normal;
}

ul.bl-toggleTab {
  display: flex;
  justify-content: center;
}
ul.bl-toggleTab li {
  display: inline-block;
}
ul.bl-toggleTab li .is-selected {
  text-decoration: underline;
}
ul.bl-toggleTab li:not(:last-child) {
  margin-right: 4em;
}
.ly-news ul.bl-toggleTab {
  margin-bottom: 40px;
}

.bl-vertCard .bl-vertCard_imgBox {
  aspect-ratio: 331/209;
  margin-bottom: 2em;
}
.bl-vertCard .bl-vertCard_imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bl-vertCard .bl-vertCard_ttl {
  margin-top: -0.4375rem;
  margin-bottom: 1em;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.7;
}
@media screen and (max-width: 834px) {
  .bl-vertCard .bl-vertCard_ttl {
    margin-bottom: 1em;
  }
}

@media (hover: none) {
  a.bl-vertCard:active {
    text-decoration: underline;
    opacity: 0.8;
  }
}
@media (hover: hover) {
  a.bl-vertCard:hover, a.bl-vertCard:focus {
    text-decoration: underline;
    opacity: 0.8;
  }
}

ul.bl-vertCardUnit {
  display: flex;
  justify-content: space-between;
}
ul.bl-vertCardUnit li {
  flex: 0 1 28.05%;
}
@media screen and (max-width: 834px) {
  ul.bl-vertCardUnit {
    flex-direction: column;
  }
  ul.bl-vertCardUnit li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 60px;
  }
}

.el-beforeIconBtn {
  position: relative;
  display: inline-block;
  padding-top: clamp(0.7em, 1vw, 1.1875em);
  padding-right: min(3vw, 2.3125em);
  padding-bottom: clamp(0.7em, 1vw, 1.1875em);
  padding-right: min(3vw, 2.3125em);
  padding-left: calc(min(3vw, 2.3125em) + 2em);
  background-color: #978f10;
  color: #ffffff;
  line-height: 1;
}
.el-beforeIconBtn::before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: min(3vw, 2.2em);
  transform: translateY(-50%);
  width: 1.375em;
  aspect-ratio: 1/1;
  background-image: url("../img/calender.png");
  background-size: cover;
}

@media (hover: none) {
  button.el-beforeIconBtn:active {
    opacity: 0.7;
  }
}
@media (hover: hover) {
  button.el-beforeIconBtn:hover, button.el-beforeIconBtn:focus {
    opacity: 0.7;
  }
}

.ly-header .el-beforeIconBtn_wrapper {
  flex: 1 0 auto;
  text-align: right;
}

.el-beforeIconHeading {
  font-size: 2.25rem;
  font-weight: normal;
  text-align: center;
}
.el-beforeIconHeading::before {
  content: "";
  display: block;
  width: 40px;
  aspect-ratio: 1/1;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 6px;
  background-image: url("../img/logo02.png");
  background-image: image-set(url("../img/logo02.png") 1x, url("../img/logo02@2x.png") 2x);
}
.ly-plan .el-beforeIconHeading {
  margin-bottom: 1.3em;
}
.ly-news .el-beforeIconHeading {
  margin-bottom: 0.6em;
}
.ly-access .el-beforeIconHeading {
  margin-bottom: 1.75em;
}

.el-btn {
  display: inline-block;
  padding: 0.7em 3em;
  border: 1px solid #707070;
}
@media screen and (max-width: 834px) {
  .ly-service .el-btn {
    border-color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media (hover: none) {
  a.el-btn:active {
    background-color: #707070;
    color: #ffffff;
  }
}
@media (hover: hover) {
  a.el-btn:hover, a.el-btn:focus {
    background-color: #707070;
    color: #ffffff;
  }
}
@media screen and (max-width: 834px) and (hover: none) {
  .ly-service a.el-btn:active {
    background-color: #ffffff;
    color: #000000;
  }
}
@media screen and (max-width: 834px) and (hover: hover) {
  .ly-service a.el-btn:hover, .ly-service a.el-btn:focus {
    background-color: #ffffff;
    color: #000000;
  }
}

.el-closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
}
.el-closeBtn > span {
  position: relative;
  display: block;
  width: 100%;
}
.el-closeBtn > span::before, .el-closeBtn > span::after {
  position: absolute;
  content: "";
  display: block;
  background: #707070;
  width: 100%;
  height: 2px;
  border-radius: 100vh;
}
.el-closeBtn span::before {
  top: -1px;
  transform: rotate(-45deg);
}
.el-closeBtn span::after {
  bottom: -1px;
  transform: rotate(45deg);
}
.bl-modal .el-closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 18px;
}

.el-copyright {
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: 0.03em;
}

.el-copyright_wrapper {
  text-align: center;
}

.el-flexibleBr {
  display: inline-block;
}

.el-googleMap {
  max-width: 792px;
  width: 100%;
  aspect-ratio: 792/592;
}
.el-googleMap iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.ly-access .el-googleMap {
  margin-right: auto;
  margin-left: auto;
}

.el-notice::before {
  content: "*";
  display: inline-block;
  vertical-align: -0.15em;
  padding-right: 0.3em;
}

.un-fv {
  position: relative;
}

.un-fv_copy {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 70.5%;
  transform: translate(-50%, -50%);
  height: 22.1rem;
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 2.125rem;
  letter-spacing: 0.25em;
  line-height: 2.4;
  color: #ffffff;
}
@media screen and (max-width: 834px) {
  .un-fv_copy {
    line-height: 2;
  }
}

.un-fv_slider {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  overflow: hidden;
}

.un-fv_slide {
  grid-area: 1/1/-1/-1;
  width: 100%;
  min-height: 600px;
  aspect-ratio: 1920/1079;
}
.un-fv_slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.un-fv_slide:nth-child(1) {
  animation-name: slider;
  animation-duration: 21s;
  animation-delay: -3s;
  animation-iteration-count: infinite;
  opacity: 0;
}

.un-fv_slide:nth-child(2) {
  animation-name: slider;
  animation-duration: 21s;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  opacity: 0;
}

.un-fv_slide:nth-child(3) {
  animation-name: slider;
  animation-duration: 21s;
  animation-delay: 11s;
  animation-iteration-count: infinite;
  opacity: 0;
}

@keyframes slider {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  14.2857142857% {
    opacity: 1;
  }
  33.3333333333% {
    opacity: 1;
  }
  47.619047619% {
    transform: scale(1.15);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.js-disableScroll {
  overflow: hidden !important;
}

.js-fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.js-fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.js-hidden {
  display: none !important;
}