@use "../configure/variable" as *;
@use "../configure/function" as *;

.ly-access {
  padding-top: 60px;
  padding-bottom: 110px;
  background-color: $cLightGray;
  background-image: url(img("bg.jpg"));
  .ly-access_inner {
    max-width: $wBase;
    margin-right: auto;
    margin-left: auto;
    padding-right: $plrPC;
    padding-left: $plrPC;
    @include mq($bp) {
      padding-right: $plrSP;
      padding-left: $plrSP;
    }
  }
}
