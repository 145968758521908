@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-horizCard {
  display: flex;
  background-color: $cLightGray;
  padding: 15px 10px;

  .bl-horizCard_imgBox {
    flex: 100px 0 0;
    aspect-ratio: 1/1;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include mq($bp) {
      flex: max(16%, 70px) 0 0;
      margin-right: 10px;
    }
  }

  .bl-horizCard_body {
    time {
      display: block;
      margin-bottom: 0.3em;
      font-size: rem(14px);
      line-height: 1;
    }
    .bl-horizCard_txt {
      @include line-clamp(2);
    }
  }
}
a.bl-horizCard {
  @include hover {
    opacity: 0.8;
  }
}
