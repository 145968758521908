@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-modal {
  position: relative;
  width: min(550px, 100%);
  max-height: 100%;
  border: 1px solid $cGray;
  background-color: $cWhite;
  padding-top: clamp(10px, 4vh, 30px);
  padding-bottom: clamp(30px, 10vh, 90px);
  overflow: auto;
}
.bl-modal_bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px $plrSP;
  background-color: rgba(#fff, 0.85);
  z-index: map-get($zIndex, modal);
}
.bl-modal_inner {
  max-width: 430px;
  margin-right: auto;
  margin-left: auto;
  padding-right: $plrPC;
  padding-left: $plrPC;
  @include mq($bp) {
    padding-right: $plrSP;
    padding-left: $plrSP;
  }
}
