@use "../configure/variable" as *;
@use "../configure/function" as *;

ul.bl-vertCardUnit {
  display: flex;
  justify-content: space-between;
  li {
    flex: 0 1 28.05%;
  }

  @include mq($bp) {
    flex-direction: column;
    li:not(:last-child) {
      margin-right: 0;
      margin-bottom: 60px;
    }
  }
}
