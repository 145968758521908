@use "../configure/variable" as *;
@use "../configure/function" as *;

.ly-copyright {
  padding-top: 12px;
  padding-bottom: 15px;
  background-color: $cBlack;
  color: $cWhite;
  .ly-copyright_inner {
    padding-right: $plrPC;
    padding-left: $plrPC;
    @include mq($bp) {
      padding-right: $plrSP;
      padding-left: $plrSP;
    }
  }
}
