@use "../configure/variable" as *;
@use "../configure/function" as *;

ul.bl-horizCardUnit {
  display: grid;
  column-gap: 46px;
  row-gap: 20px;
  align-items: stretch;
  grid-template-columns: repeat(2, 1fr);
  @include mq($bp) {
    grid-template-columns: repeat(1, 1fr);
  }
}
