@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-breadcrumb {
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  .ly-cont & {
    margin-bottom: 6.125em;
  }
}

.bl-breadcrumb_txt {
  line-height: 1;
  &:not(:last-of-type) {
    &::after {
      content: "";
      transform: rotate(45deg) translateY(-0.2em);
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-left: 0.3em;
      margin-right: 0.6em;
      border-top: 1px solid $cBlack;
      border-right: 1px solid $cBlack;
    }
  }

  a {
    @include hover {
      opacity: 0.6;
    }
  }
}
