@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-globalNav {
  @include mq($bp) {
    display: none;
  }

  ul {
    display: flex;
    justify-content: flex-start;
  }
  li {
    display: inline-block;
    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }
  a {
    @include hover {
      opacity: 0.6;
    }
  }

  .bl-headerCont & {
    flex: 1 1 auto;
  }
}
