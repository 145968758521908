@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-footerCont {
}
.bl-footerCont_nav {
  margin-bottom: 78px;

  ul {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    li {
      writing-mode: vertical-rl;
      text-orientation: upright;
      font-size: rem(18px);
      letter-spacing: 0.8em;
    }
    li:not(:last-of-type) {
      margin-left: 1em;
    }
    a {
      @include hover {
        opacity: 0.6;
      }
    }
  }
}

.bl-footerCont_logo {
  width: 145px;
  aspect-ratio: 145/92;
  margin-right: auto;
  margin-bottom: 76px;
  margin-left: auto;
}
.bl-footerCont_info {
  font-style: normal;
  line-height: 1;
  &_wrapper {
    display: flex;
    justify-content: center;
    &:not(:last-of-type) {
      margin-bottom: 1.4375em;
    }
  }

  &_01 {
    margin-right: 1em;
  }
  &_02 {
    letter-spacing: 0.14em;
  }
  &_03 {
    margin-right: 2em;
  }
}
