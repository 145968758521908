@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-media {
  display: flex;

  @include mq($bp) {
    display: grid;
  }

  .bl-media_imgBox {
    flex: 0 1 54.6%;
    aspect-ratio: 1050/545;
    margin-right: 6.5%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include mq($bp) {
      margin-right: 0;
      aspect-ratio: initial;
      grid-area: 1/-1;
    }
  }
  .bl-media_body {
    flex: 0 1 auto;
    padding: 2em 1em;
    @include mq($bp) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      grid-area: 1/-1;
      padding: 3em $plrSP;
      background-color: rgba(#000, 0.5);
      color: $cWhite;
    }
  }

  .bl-media_ttl {
    margin-top: calc(-1 * halfLeading(rem(36px), 1.72));
    margin-bottom: calc(min(4vw, 70px) - halfLeading(rem(36px), 1.72));
    font-weight: normal;
    font-size: rem(36px);
    line-height: 1.72;

    @include mq($bp) {
      font-size: rem_sp(24px);
      margin-bottom: calc(30px - halfLeading(rem(24px), 1.72));
    }
  }

  .bl-media_txt {
    margin-bottom: min(4vw, 65px);
    @include mq($bp) {
      margin-bottom: 30px;
    }
  }

  &.bl-media__rev {
    flex-direction: row-reverse;

    .bl-media_imgBox {
      margin-right: 0;
      margin-left: 7.4%;

      @include mq($bp) {
        margin-left: 0;
      }
    }

    .bl-media_body {
      @include mq($bp) {
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
}
