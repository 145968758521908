@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-accessCont {
  .bl-accessCont_media {
    display: flex;
    margin-bottom: 70px;
    @include mq($bp) {
      flex-direction: column;
    }
  }
  .bl-accessCont_media_imgBox {
    flex: 55.6% 1 0;
    aspect-ratio: 656/370;
    margin-right: 7%;
    @include mq($bp) {
      flex: auto 1 0;
      margin-right: 0;
      margin-bottom: 2em;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bl-accessCont_media_body {
    padding-top: 0.75em;
    dl,
    dt,
    dd {
      margin-bottom: 0.5em;
      @include mq($bp) {
        margin-bottom: 1em;
      }
    }
    dt {
      font-weight: 800;
    }
  }
}
