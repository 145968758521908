@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-burgerBtn {
  display: none;

  @include mq($bp) {
    z-index: map-get($zIndex, burgerBtn);
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;

    & > span,
    & > span::before,
    & > span::after {
      display: block;
      background: $cBlack;
      width: 100%;
      height: 2px;
      border-radius: 100vh;
      transition: 0.3s;
      .ly-header.is-transparent & {
        background-color: $cWhite;
      }
    }
    & > span {
      position: relative;
    }
    & > span::before {
      position: absolute;
      content: "";
      top: -9px;
    }
    & > span::after {
      position: absolute;
      content: "";
      bottom: -9px;
    }
    &.is-clicked {
      span {
        background: rgba(0, 0, 0, 0) !important;
      }
      span::before {
        top: 0;
        transform: rotate(-45deg);
        background-color: $cBlack !important;
      }
      span::after {
        bottom: 0;
        transform: rotate(45deg);
        background-color: $cBlack !important;
      }
    }
    .ly-header & {
      flex: 0 0 20px;
      margin-left: 10px;
    }
  }
}
