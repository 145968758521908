@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-headerCont {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bl-headerCont_logo {
    margin-right: 6.38%;
  }
  .bl-headerCont_logo_white {
    display: none;
  }
  .bl-headerCont_logo_black {
    display: block;
  }
  .ly-header.is-transparent & {
    .bl-headerCont_logo_white {
      display: block;
    }
    .bl-headerCont_logo_black {
      display: none;
    }
  }
}
