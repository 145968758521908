@use "../configure/variable" as *;
@use "../configure/function" as *;

.ly-cont {
  padding-top: 36px;
  padding-bottom: 150px;
}
.ly-cont_inner {
  max-width: $wBase;
  margin-right: auto;
  margin-left: auto;
  padding-right: $plrPC;
  padding-left: $plrPC;
  @include mq($bp) {
    padding-right: $plrSP;
    padding-left: $plrSP;
  }
}

.ly-cont {
  &.ly-cont__onsen {
    padding-bottom: 96px;
  }
}
