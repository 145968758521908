@use "../configure/variable" as *;
@use "../configure/function" as *;
@use "sass:math";

.un-fv {
  position: relative;
}
.un-fv_copy {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 70.5%;
  transform: translate(-50%, -50%);
  height: rem(34px * 8 * 1.3);
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: rem(34px);
  letter-spacing: 0.25em;
  line-height: 2.4;
  color: $cWhite;
  @include mq($bp) {
    line-height: 2;
  }
}

.un-fv_slider {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  overflow: hidden;
}
.un-fv_slide {
  grid-area: 1/1/-1/-1;
  width: 100%;
  min-height: 600px;
  aspect-ratio: 1920/1079;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

$slider-duration: 4s;
$slider-transition: 3s;
$slider-pages: 3;
$slider-totalDuration: ($slider-duration + $slider-transition) * $slider-pages;

@for $i from 1 through $slider-pages {
  .un-fv_slide:nth-child(#{$i}) {
    animation-name: slider;
    animation-duration: $slider-totalDuration;
    animation-delay: ($i - 1) *
      ($slider-duration + $slider-transition) -
      $slider-transition;
    animation-iteration-count: infinite;
    opacity: 0;
  }
}

@keyframes slider {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  #{math.div($slider-transition,$slider-totalDuration)*100}% {
    opacity: 1;
  }
  #{math.div($slider-transition + $slider-duration,$slider-totalDuration)*100}% {
    opacity: 1;
  }
  #{math.div($slider-duration + (2 * $slider-transition),$slider-totalDuration)*100}% {
    transform: scale(1.15);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
