@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-copy {
  text-align: center;

  .ly-cont & {
    margin-top: -1 * halfLeading(1rem, 2);
    margin-bottom: calc(78px - halfLeading(1rem, 2));
  }
}
