@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-table {
  padding-top: min(10vw, 96px);
  padding-bottom: min(8vw, 74px);
  border-top: 1px solid $cSlightlyLightGray;
  border-bottom: 1px solid $cSlightlyLightGray;
}
.bl-table_ttl {
  margin-top: calc(-1 * halfLeading(rem(26px), 2));
  margin-bottom: calc(51px - halfLeading(rem(26px), 2));
  font-size: rem(26px);
  font-weight: normal;
  text-align: center;
}
table.bl-table_cont {
  max-width: 880px;
  margin-right: auto;
  margin-left: auto;
  border-collapse: collapse;
  th,
  td {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    border-top: 1px solid $cSlightlyLightGray;
    font-size: rem(15px);
    line-height: 1.47;
    text-align: left;
    vertical-align: top;
  }
  tr:last-of-type {
    th,
    td {
      border-bottom: 1px solid $cSlightlyLightGray;
    }
  }
  th {
    white-space: nowrap;
    padding-right: min(10vw, 10.7em);
    font-weight: normal;
  }
}
