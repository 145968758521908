@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-vertCard {
  .bl-vertCard_imgBox {
    aspect-ratio: 331/209;
    margin-bottom: 2em;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bl-vertCard_body {
  }

  .bl-vertCard_ttl {
    margin-top: calc(-1 * halfLeading(rem(20px), 1.7));
    margin-bottom: 1em;
    font-weight: normal;
    font-size: rem(20px);
    line-height: 1.7;
    @include mq($bp) {
      margin-bottom: 1em;
    }
  }
}
a.bl-vertCard {
  @include hover {
    text-decoration: underline;
    opacity: 0.8;
  }
}
