@use "../configure/variable" as *;
@use "../configure/function" as *;

.ly-service {
  padding-top: 106px;
  padding-bottom: 78px;
  background-color: $cWhite;

  @include mq($bp) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ly-service_inner {
  }
}
