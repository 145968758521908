@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-noticeList {
  line-height: 1.5;

  .bl-overlapMedia & {
    font-size: rem(14px);
  }
}
