@use "../configure/variable" as *;
@use "../configure/function" as *;

.bl-overlapMedia {
  display: flex;
  padding-bottom: calc(-1 * (-5% - 80px));
  @include mq($bp) {
    flex-direction: column;
    padding-bottom: 0;
  }
  &.bl-overlapMedia__rev {
    flex-direction: row-reverse;
    @include mq($bp) {
      flex-direction: column;
    }
  }
}
.bl-overlapMedia_imgBox {
  flex: 0 1 44.5%;
  aspect-ratio: 525/300;
  z-index: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include mq($bp) {
    flex: 0 1 auto;
    width: 92%;
  }
}
.bl-overlapMedia_body {
  &_wrapper {
    flex: 1 0 55%;
    background-image: url(img("bg.jpg"));
    margin-top: 5%;
    margin-bottom: calc(-5% - 80px);
    margin-left: -13.3%;
    padding-top: clamp(20px, 6%, 60px);
    padding-right: clamp(20px, 6%, 60px);
    padding-bottom: clamp(20px, 6%, 60px);
    padding-left: clamp(15% + 30px, 20%, 15% + 80px);

    @include mq($bp) {
      flex: 0 1 auto;
      width: 92%;
      align-self: end;
      margin-top: -30%;
      margin-bottom: 0;
      margin-left: 0;
      padding-top: clamp(30% + 20px, 35%, 30% + 60px);
      padding-bottom: clamp(20px, 5%, 60px);
      padding-left: clamp(20px, 3%, 60px);
    }
  }
}
.bl-overlapMedia_ttl {
  font-size: rem(24px);
  font-weight: normal;
  margin-top: calc(-1 * halfLeading(rem(24px), 2));
  margin-bottom: calc(
    clamp(rem(24px), 5vw, rem(52px)) - halfLeading(rem(24px), 2)
  );
}
.bl-overlapMedia_txt {
  margin-top: calc(-1 * halfLeading(rem(16px), 2));
  margin-bottom: calc(
    clamp(rem(24px), 5vw, rem(52px)) - halfLeading(rem(16px), 2)
  );
}

.bl-overlapMedia__rev {
  .bl-overlapMedia_imgBox {
    @include mq($bp) {
      align-self: end;
    }
  }
  .bl-overlapMedia_body {
    &_wrapper {
      margin-right: -13.3%;
      margin-left: 0;
      padding-right: clamp(15% + 30px, 20%, 15% + 80px);
      padding-left: clamp(20px, 6%, 60px);

      @include mq($bp) {
        align-self: start;
        margin-right: 0;
        padding-right: clamp(20px, 3%, 60px);
      }
    }
  }
}

.bl-overlapMediaUnit .bl-overlapMedia {
  &:not(:last-child) {
    margin-bottom: 5.25em;
  }
  &:last-child {
    margin: 0;
  }
}
