@use "../configure/variable" as *;
@use "../configure/function" as *;

.js-fadeIn {
  animation-name: fadeIn;
  animation-duration: map-get($duration, fade);
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
